









import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';

export default Vue.extend({
  components: {
    LoadingIndicator
  },
  data() {
    return {
      validating: true
    };
  },
  computed: {
    ...mapStores(useUsersStore)
  },
  async created() {
    await this.usersStore.emailValidation(
      this.$route.query.id as string,
      this.$route.query.token as string
    );
    this.validating = false;
    this.$router.push({ name: 'MyAccount' });
  }
});
